<template>
  <div class="indexMobile">
    <div v-if="showSafeTips" class="safe-tips" @click="handleShowDetail(18, 317, 0)">
      <span>
        {{ safeTips }}
        <span class="safe-tips-detail">{{ ">>详情" }}</span>
      </span>

      <img
        class="safe-tips-close-icon"
        :src="closeIcon"
        alt=""
        @click.stop="hideSafeTips"
      />
    </div>

    <section class="index-top">
      <div class="top-content">
        <swiper
          class="swiper"
          ref="topSwiperPc"
          :options="topSwiperPcSwiperOptions"
        >
          <swiper-slide
            v-for="(item, index) in gData.top"
            :key="index"
            class="swiperItem"
          >
            <div class="info-content" :style="setBgUrl(item.img)">
              <div class="txt-container" v-if="index === 0">
                <div class="txt-first">我们的愿景</div>
                <div class="txt-second">
                  中国领先的<br />
                  开发者孵化运营平台
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <div class="top-swiper-scrollbar-content">
          <div class="top-swiper-scrollbar"></div>
        </div>
      </div>
    </section>
    <!-- 企业价值观 -->
    <section
      class="company-values aniamtionCode"
      id="companyValuesMob"
      ref="companyValuesMob"
    >
      <h2 class="title">企业价值观</h2>
      <p class="subtitle">小迈人共同认可的行为准则，凝聚团队的核心力量</p>
      <div class="values-content-app">
        <swiper
          ref="valuesSwiperMobile"
          :options="valuesSwiperOptions"
          class="content swiper-wrapper"
        >
          <swiper-slide
            class="content-container swiper-slide"
            v-for="(item, index) in gData.values"
            :key="index"
          >
            <img class="img" :src="item.img" :alt="item.title" />
            <h3 class="sub-title index-secondTitle">{{ item.title }}</h3>
            <p class="sub-content" v-html="item.info"></p>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <!-- 公司规摸 -->
    <section
      class="company-extenet aniamtionCode"
      id="companyExtenetMob"
      ref="companyExtenetMob"
    >
      <!-- <img
        class="company-extenet-block2"
        src="//img.ibestfanli.com/officialWeb/moblieBlock2.png"
      /> -->
      <h2 class="index-firstTitle">致力于构建移动应用生态</h2>
      <p class="index-content extenet-explain">
        通过领先的开发者孵化运营能力和产业增长能力帮助开发者实现成功
      </p>
      <div class="extenet-content">
        <div
          class="extenet-content-item"
          v-for="(item, index) in gData.extenets"
          :key="index"
        >
          <div class="num-container">
            <span class="num">{{ item.num }}</span
            ><span class="unit">{{ item.isShowYiTxt ? "亿" : "" }}+</span>
          </div>
          <div class="txt-x-content">{{ item.txtXContent }}</div>
        </div>
      </div>
      <!-- <img
        class="company-extenet-block3"
        src="//img.ibestfanli.com/officialWeb/moblieBlock3.png?v=1"
      /> -->
    </section>
    <!-- 合作模式 -->
    <section
      class="company-pattern aniamtionCode"
      id="companyPatternMob"
      ref="companyPatternMob"
    >
      <h2 class="index-firstTitle">成熟的商业合作模式</h2>
      <p class="index-content pattern-explain">
        利用数字化建设和产品化运营能力，驱动实现持续的创收增长
      </p>
      <div class="pattern-content-app">
        <swiper
          ref="patternSwiperMobile"
          :options="patternSwiperOptions"
          class="content swiper-wrapper"
        >
          <swiper-slide
            class="content-container swiper-slide"
            v-for="(item, index) in gData.patterns"
            :key="index"
          >
            <div class="item-title-container">
              <span class="item-title">{{ item.title }}</span>
              <img class="img" :src="item.image" alt="" />
            </div>
            <div class="item-content-container">
              <div v-for="(subItem, subIndex) in item.contents" :key="subIndex">
                <div class="sub-title index-threeTitle">
                  <i
                    v-bind:style="{ backgroundImage: `url(${subItem.icon})` }"
                  ></i
                  >{{ subItem.title }}
                </div>
                <p class="sub-content">{{ subItem.content }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <!-- Add Pagination -->
        <div class="swiper-pagination2 swiper-paginationSpecial"></div>
      </div>
    </section>
    <!-- 加入小迈 -->
    <section
      class="company-join aniamtionCode"
      id="joinMmMobile"
      ref="joinMmMobile"
    >
      <!-- <img class="company-join-block4" src="http://img.ibestfanli.com/officialWeb/moblieBlock4.png" /> -->
      <img
        class="company-join-block5"
        src="//img.ibestfanli.com/officialWeb/moblieBlock5.png?v=1"
      />
      <img
        class="company-join-block6"
        src="//img.ibestfanli.com/officialWeb/moblieBlock7.png"
      />
      <!-- <img
        class="company-join-block7"
        src="//img.ibestfanli.com/officialWeb/moblieBlock6.png"
      /> -->
      <div class="littleCube-content">
        <div
          class="littleCube littleCube1 aniamtionCode"
          ref="littleCube1"
        ></div>
        <div
          class="littleCube littleCube2 aniamtionCode"
          ref="littleCube2"
        ></div>
        <div
          class="littleCube littleCube3 aniamtionCode"
          ref="littleCube3"
        ></div>
        <div
          class="littleCube littleCube4 aniamtionCode"
          ref="littleCube4"
        ></div>
      </div>

      <h2 class="index-firstTitle">加入小迈</h2>
      <p class="index-content">在这，一起探索你的未来</p>
      <div class="join-content" @mousedown="skipUrl">
        <div class="join-btn">开启探索</div>
      </div>
    </section>
  </div>
</template>

<script>
import Config from "../../../utils/Config";
import CommonMethod from "utils/CommonMethod";
import closeIcon from "../../../assets/img/mobile/index/top/safe-tips-icon.png";
export default {
  name: "indexMobile",
  props: {
    gData: Object,
  },
  created() {
    this.commonMethod = new CommonMethod({
      context: this,
      navName: "",
    });
  },
  data() {
    return {
      topSwiperPcSwiperOptions: {
        direction: "horizontal",
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".top-swiper-scrollbar",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          click: function() {
            if (this.realIndex === 1) {
              window.open(
                "https://app.mokahr.com/campus-recruitment/xmiles/37612"
              );
            }
          },
        },
      },

      valuesSwiperOptions: {
        spaceBetween: 20,
        autoplay: process.env.NODE_ENV === "production" ? true : false,
        centeredSlides: true,
        loop: true,
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      patternSwiperOptions: {
        spaceBetween: 20,
        autoplay: false,
        centeredSlides: true,
        loop: true,
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination2",
          clickable: true,
        },
      },

      // 防诈骗提醒
      safeTips:
        "近日，我司发现有不法分子假冒我司已停运APP“车主无忧”之名义企图诈骗等，在此声明：“车主无忧”已于2021年11月停运，请勿上当受骗！",
      closeIcon,
      showSafeTips: false,
    };
  },
  methods: {
    handleShowDetail(categoryId, articleId, index) {
      window.location.href = `${Config.domain}/dynamicDetail.html?categoryId=${categoryId}&id=${articleId}&index=${index}`;
    },
    hideSafeTips() {
      this.showSafeTips = false;
    },
    skipUrl() {
      window.location.href = `${Config.domain}/about.html`;
    },
    scrollHandle() {
      this.commonMethod.modelFadeInupMobile({ id: "companyExtenetMob" });
      this.commonMethod.modelFadeInupMobile({ id: "companyPatternMob" });
      this.commonMethod.modelFadeInupMobile({ id: "joinMmMobile" });
      // this.commonMethod.modelFadeInup("companyExtenetMob", 750, "weakFadeInUp");
      // this.commonMethod.modelFadeInup("companyPatternMob", 750, "weakFadeInUp");
      // this.commonMethod.modelFadeInup("joinMmMobile", 750, "weakFadeInUp");
    },
  },
  computed: {
    valuesSwiperCtr() {
      return this.$refs.valuesSwiperMobile.swiper;
    },

    /* eslint-disable */
    setBgUrl(url) {
      return (url) => {
        let str = "";
        str = `background-image: url(${url})`;
        return str;
      };
    },
  },
  mounted() {
    this.commonMethod.init();
    this.commonMethod.modelFadeInupMobile({
      id: "companyValuesMob",
      autoPlay: true,
    });
    window.addEventListener("scroll", this.scrollHandle, true);

    // banner 图少于2时候，停止播放
    if (this.gData.top && this.gData.top.length < 2) {
      this.$refs.topSwiperPc.swiper.autoplay.stop();
    }
  },
};
</script>
<style lang="less" scoped>
section {
  position: relative;
}
h2.index-firstTitle {
  font-size: 24px;
  padding-top: 40px;
  text-align: center;
  font-weight: normal;
  font-weight: bold;
}
// h3.index-secondTitle {
//   line-height: 100px;
//   font-size: 69px;
// }
.index-threeTitle {
  font-size: 54px;
}
.index-content {
  padding: 5px 0 20px 0;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
.index-top {
  position: relative;
  width: 100%;
  height: 390px;
  // background: url("//img.ibestfanli.com/xmilesWebsite2/index-top-app.png") no-repeat;
  background-size: cover;

  .txt-container {
    position: absolute;
    left: 20px;
    top: 71px;
    box-sizing: border-box;
    width: 216px;
    border-radius: 0 184px 0 0;
  }
  .txt-container .txt-first {
    margin-bottom: 4px;
    font-size: 14px;
    color: #666;
    font-weight: 500;
    color: #ffffff;
  }
  .txt-container .txt-second {
    width: 216px;
    height: 72px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 36px;
  }
  .top-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    .swiper-container,
    .info-content {
      width: 100%;
      height: 100%;

      &.info-content {
        background-size: cover;
        background-position: center center;
      }
    }
  }
}

/* 企业价值start */
.company-values {
  .aniBlockMobile;
  background: #ffffff;

  .title {
    margin: 0;
    padding: 40px 0 0 0;
    // height: 138px;
    font-size: 24px;
    color: #222222;
    // line-height: 138px;
    text-align: center;
  }
  .subtitle {
    padding: 5px 0 20px 0;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    text-align: center;
  }
  .values-content-app {
    position: relative;
    // height: 1483px;

    .content-container {
      // width: 303px;
      width: 80.8%;
      // height: 336px;
      box-shadow: 0 19px 115px 0 rgba(239, 244, 250, 1);
      border-radius: 10px;
      .img {
        width: 100%;
      }
      .sub-title {
        padding: 20px 0 8px 20px;
        font-size: 18px;
      }
      .sub-content {
        margin-left: 20px;
        padding-bottom: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }
  }
  // 方块
  .bg-block1 {
    position: absolute;
    top: -904px;
    width: 1265px;
  }
}
/* 企业价值end */

/* 规模start */
.company-extenet {
  .aniBlockMobile;
  // height: 914px;
  // 方块
  .company-extenet-block3 {
    position: absolute;
    top: 580px;
    left: 979px;
    width: 461px;
    height: 941px;
    // z-index: 100;
  }
  .company-extenet-block2 {
    position: absolute;
    top: -210px;
    left: -192px;
    width: 456px;
    height: 456px;
  }
  .extenet-content {
    box-sizing: border-box;
    padding: 30 - 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  .extenet-content-item {
    text-align: center;
    .num-container {
      display: flex;
      justify-content: center;
    }
    .num {
      font-size: 36px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #3292ff;
    }
    .unit {
      font-size: 16px;
      font-weight: 400;
      color: #3292ff;
      margin-top: 17px;
    }
    .txt-container {
      font-size: 12px;
      color: #222;
    }
    .txt-x-content {
      font-size: 12px;
      margin-top: 4px;
      color: #222;
    }
  }
}
/* 规模end */

/* 成熟模式start */
.company-pattern {
  .aniBlockMobile;
  .pattern-content-app {
    // height: 1610px;
    .swiper-container {
      overflow: inherit;
    }
    z-index: 100;

    .content {
      // height: 1596px;
    }
  }
  .content-container {
    box-sizing: border-box;
    // width: 303px;
    width: 80.8%;
    min-height: 405px;
    // width: 80.8%;
    // height: 1402px;
    padding-left: 20px;
    margin-top: 10px;
    box-shadow: 0 19px 115px 0 rgba(239, 244, 250, 1);
    border-radius: 10px;
    background-color: #fff;
    z-index: 1000000;
    padding-bottom: 20px;

    .item-title-container {
      display: flex;
      justify-content: space-between;
      margin-top: 26px;
    }
    .item-title {
      display: inline-block;
      margin-top: 16px;
      font-size: 28px;
      color: #3292ff;
    }
    .img {
      width: 131px;
      height: 110px;
    }
    .sub-title {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
    }
    i {
      display: inline-block;
      height: 18px;
      width: 18px;
      position: relative;
      top: 4px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .sub-content {
      padding-right: 20px;
      // width: 1010px;
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #666;
      line-height: 24px;
    }
  }
}

/* 加入小迈start */
.company-join {
  .aniBlockMobile;
  background: #ffffff;
  // height: 929px;
  border-bottom: 2px solid #e5e5e5;
  overflow: hidden;

  .join-content {
    display: block;
    width: 335px;
    height: 54px;
    background: #3292ff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #ffffff;
    font-size: 16px;
  }
  .mail {
    font-size: 46px;
    font-weight: 400;
    color: #222222;
    line-height: 92px;
  }
  .company-join-block4 {
    position: absolute;
    top: -315px;
    left: -75px;
    width: 407px;
    height: 407px;
  }
  .company-join-block5 {
    position: absolute;
    top: -13px;
    right: 0;
    width: 42px;
    height: 111px;
  }
  .company-join-block6 {
    position: absolute;
    top: 20px;
    left: 0;
    width: 32px;
    height: 80px;
  }
  .company-join-block7 {
    position: absolute;
    top: 142px;
    left: 50%;
    margin-left: -550px;
    width: 1102px;
    height: 230px;
  }

  .littleCube-content {
    position: relative;
    height: 100%;
    width: 84.6%;
    margin: 0 auto;
  }

  .littleCube {
    z-index: -1;
    position: absolute;
  }
  .littleCube1 {
    width: 17px;
    height: 17px;
    background: rgba(50, 146, 255, 0.1);
    border-radius: 4px;
    top: 85px;
    left: 38px;
    transform: rotate(-35deg);
  }
  .littleCube2 {
    width: 16px;
    height: 16px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #3292ff;
    top: 6px;
    left: 78px;
    transform: rotate(35deg);
  }
  .littleCube3 {
    width: 16px;
    height: 16px;
    background: #3292ff;
    border-radius: 3px;
    top: 20px;
    right: 70px;
    transform: rotate(35deg);
  }
  .littleCube4 {
    width: 33px;
    height: 33px;
    top: 78px;
    right: 30px;
    background-image: url("//img.ibestfanli.com/xmilesWebsite2/littleCube6.png");
    background-size: 100% 100%;
  }
}
/* 加入小迈end */

// 优化 < 768 在pc上看的样式 (550节点)
// @media screen and (min-width: 550px) and (max-width: 768px) {
//   .index-firstTitle{
//     font-size: 32px !important;
//   }
//   .index-content{
//     font-size: 16px !important;
//     padding: 10px 0 40px 0;
//   }
//   .company-values{
//     .title{
//       font-size: 32px;
//     }
//     .subtitle{
//       font-size: 16px;
//       padding: 10px 0 40px 0;
//     }
//     .values-content-app{
//       .content-container{
//         .sub-title{
//           font-size: 24px;
//           padding: 20px 0 17px 20px;
//         }
//         .sub-content{
//           // height: 150px;
//           font-size: 19px;
//           padding-bottom: 27px;
//           // min-height: 140px;
//         }
//       }
//     }
//   }

//   .company-extenet {
//     .extenet-content{
//       padding: 10px 27px 0 27px;
//     }
//     .extenet-content-item{
//       .num{
//         font-size: 48px;
//       }
//       .unit{
//         font-size: 21px;;
//         margin-top: 23px;
//         margin-left: 5px;
//       }
//       .txt-x-content{
//         font-size: 16px;;
//       }
//     }
//   }

//   .company-pattern{
//     .content-container{
//       min-height: 505px;
//       padding-bottom: 27px;

//       .img{
//         width: 175px;
//         height: 147px;
//       }

//       .item-title{
//         font-size: 37px;
//       }

//       .sub-title{
//         font-size: 19px;
//         // margin-top: 27px;
//       }
//       .sub-content{
//         margin-top: 15px;
//         font-size: 19px;
//         // margin-top: 11px;
//         line-height: 32px;
//       }
//     }
//   }
// }
</style>
<style lang="less">
.indexMobile {
  .top-swiper-scrollbar-content {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 88;

    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;
      background: #ffffff;
      opacity: 0.4;
      border-radius: 4px;
      margin-left: 5px;

      &.swiper-pagination-bullet-active {
        width: 8px;
        opacity: 1;
      }
    }
  }
}

// swiper重置
.company-values {
  /* 滑动点容器 */
  .swiper-container {
    overflow: inherit;
    // height: 1343px;
    // padding-bottom: 77px;
  }
  // 滑动点位置
  .swiper-pagination,
  .swiper-pagination2 {
    // position: absolute;
    // left: 50%;
    // bottom: 120px;
    // transform: translateX(-50%);
    margin: 15px auto 0;
    // padding-bottom: 40px;
    right: 0;
    left: 0;
    position: relative;
  }
  // 滑动点样式
  .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background: #bbbbbb;
      opacity: 1;
      margin: 0 2.5px;
    }
    .swiper-pagination-bullet-active {
      width: 8px;
      height: 4px;
      background: #222;
      border-radius: 15px;
    }
  }
}

/* 滑动点 */
.company-pattern {
  // 滑动点位置
  .swiper-pagination,
  .swiper-pagination2 {
    // position: absolute;
    // left: 50%;
    // bottom: 90px;
    // transform: translateX(-50%);
    // right: 0;
    // left: 0;
    text-align: center;
    margin-top: 20px;
  }
  .swiper-paginationSpecial {
    // bottom: 70px;
  }
  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background: #bbbbbb;
    opacity: 1;
    margin: 0 2.5px;

    &.swiper-pagination-bullet-active {
      width: 8px;
      height: 4px;
      background: #222;
      border-radius: 15px;
    }
  }
}

.safe-tips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d7d7d;
  background: #ffeeee;

  display: flex;
  // align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid #ffcfcf;
  justify-content: space-between;

  .safe-tips-detail {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3292ff;
    cursor: pointer;
    margin-left: 4px;
    white-space: nowrap;
  }

  .safe-tips-close-icon {
    width: 32px;
    height: 32px;
    line-height: 16px;
  }
}
</style>
